

	/*
  	Flaticon icon font: Flaticon
  	Creation date: 07/09/2019 08:07
  	*/

	  @font-face {
		font-family: "Flaticon";
		src: url("../fonts/Flaticon.eot");
		src: url("../fonts/Flaticon.eot");
		src: url("../fonts/Flaticond41d.eot?#iefix") format("embedded-opentype"),
			 url("../fonts/Flaticon.woff2") format("woff2"),
			 url("../fonts/Flaticon.woff") format("woff"),
			 url("../fonts/Flaticon.ttf") format("truetype"),
			 url("../fonts/Flaticon.svg#Flaticon") format("svg");
		font-weight: normal;
		font-style: normal;
	  }
	  
	  @media screen and (-webkit-min-device-pixel-ratio:0) {
		@font-face {
		  font-family: "Flaticon";
		  src: url("Flaticon.html#Flaticon") format("svg");
		}
	  }
	  
	  [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
	  [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
		font-family: Flaticon;
			  font-size: 20px;
	  font-style: normal;
	  margin-left: 20px;
	  }